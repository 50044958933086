"use client";

import React, { useContext, createContext, PropsWithChildren } from "react";
import { JobsResponseType } from "./service";
import { ConfigType } from "app/types";

type ProviderData = {
  jobs: JobsResponseType;
  lang: string;
  config: Partial<ConfigType>;
  jobClassesRemote?: any;
  locationRemote?: any;
  languageRemote?: any;
  remoteJobs?: any;
  isRemoteWorldwide?: boolean;
  dictionary?: any;
  companyPageData: any;
  companyId: any;
  hasLogin?: boolean;
};
const CompanyDetails = createContext<ProviderData | undefined>(undefined);
interface Props extends PropsWithChildren<ProviderData> {
  name?: any;
}
export function CompanyDetailsProvider({
  children,
  jobs,
  lang,
  config,
  jobClassesRemote,
  locationRemote,
  languageRemote,
  remoteJobs,
  isRemoteWorldwide,
  dictionary,
  companyPageData,
  companyId,
  hasLogin
}: Props) {
  return (
    <CompanyDetails.Provider
      value={{
        // detail,
        jobs,
        lang,
        config,
        // jobFunctions,
        jobClassesRemote,
        locationRemote,
        languageRemote,
        remoteJobs,
        isRemoteWorldwide,
        // news,
        // businessInfo,
        dictionary,
        companyPageData,
        companyId,
        hasLogin
      }}
    >
      {children}
    </CompanyDetails.Provider>
  );
}

export function useCompanyDetail() {
  const context = useContext(CompanyDetails);
  if (context === undefined) {
    throw new Error("useCartCount must be used within a CartCountProvider");
  }
  return context;
}
