import { Card, CommWrapper } from "../card";
import React from "react";
import Image from "next/image";
import { Tooltip } from "antd";
import { useCompanyDetail } from "../../../DataProvider";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import styles from "./index.module.scss";
import {
  CommContent,
  CommContentLayout
} from "app/(new-pages)/(company-detail)/[lang]/company/[keyword]/_pc/components/Layout/index.pc";
import { CommModal } from "../comm/Modal";
import { CultureAndLifeItem } from "../comm/cultureAndLifeItem";

const VerificationIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M9.30027 0.685356C9.68905 0.304565 10.311 0.304565 10.6997 0.685356L12.6594 2.60476C12.8437 2.78531 13.0907 2.88762 13.3487 2.8903L16.0917 2.91877C16.6358 2.92442 17.0756 3.36417 17.0812 3.90834L17.1097 6.65125C17.1124 6.90927 17.2147 7.15627 17.3952 7.34061L19.3146 9.30027C19.6954 9.68905 19.6954 10.311 19.3146 10.6997L17.3952 12.6594C17.2147 12.8437 17.1124 13.0907 17.1097 13.3487L17.0812 16.0917C17.0756 16.6358 16.6358 17.0756 16.0917 17.0812L13.3487 17.1097C13.0907 17.1124 12.8437 17.2147 12.6594 17.3952L10.6997 19.3146C10.311 19.6954 9.68905 19.6954 9.30027 19.3146L7.34061 17.3952C7.15627 17.2147 6.90927 17.1124 6.65125 17.1097L3.90834 17.0812C3.36417 17.0756 2.92442 16.6358 2.91877 16.0917L2.8903 13.3487C2.88762 13.0907 2.78531 12.8437 2.60476 12.6594L0.685356 10.6997C0.304565 10.311 0.304565 9.68905 0.685356 9.30027L2.60476 7.34061C2.78531 7.15627 2.88762 6.90927 2.8903 6.65125L2.91877 3.90834C2.92442 3.36417 3.36417 2.92442 3.90834 2.91877L6.65125 2.8903C6.90927 2.88762 7.15627 2.78531 7.34061 2.60476L9.30027 0.685356Z'
        fill='#4BD9E3'
      />
      <path
        d='M6.54028 9.61217L9.08398 12.1696L13.4535 7.82938'
        stroke='black'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const PhoneIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path d='M7.08342 6.66667H12.9167V5.00001H7.08342V6.66667Z' fill='#515151' />
      <path
        d='M10.0001 15.4167C10.9206 15.4167 11.6667 14.6705 11.6667 13.75C11.6667 12.8295 10.9206 12.0833 10.0001 12.0833C9.07961 12.0833 8.33342 12.8295 8.33342 13.75C8.33342 14.6705 9.07961 15.4167 10.0001 15.4167Z'
        fill='#515151'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.41675 1.66667C4.03604 1.66667 2.91675 2.78596 2.91675 4.16667L2.91675 15.8333C2.91675 17.2141 4.03604 18.3333 5.41675 18.3333H14.5834C15.9641 18.3333 17.0834 17.2141 17.0834 15.8333V4.16667C17.0834 2.78596 15.9641 1.66667 14.5834 1.66667L5.41675 1.66667ZM4.58342 4.16667C4.58342 3.70643 4.95651 3.33334 5.41675 3.33334L14.5834 3.33334C15.0437 3.33334 15.4167 3.70643 15.4167 4.16667V15.8333C15.4167 16.2936 15.0437 16.6667 14.5834 16.6667H5.41675C4.95651 16.6667 4.58341 16.2936 4.58341 15.8333L4.58342 4.16667Z'
        fill='#515151'
      />
    </svg>
  );
};

const EmailIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M10.4622 11.5267L16.1556 7.73115L15.2311 6.3444L9.99993 9.83182L4.76881 6.3444L3.84431 7.73115L9.53768 11.5267C9.8176 11.7133 10.1823 11.7133 10.4622 11.5267Z'
        fill='#515151'
      />
      <path
        d='M4.16663 2.5C2.78591 2.5 1.66663 3.61929 1.66663 5V15C1.66663 16.3807 2.78591 17.5 4.16663 17.5H15.8333C17.214 17.5 18.3333 16.3807 18.3333 15V5C18.3333 3.61929 17.214 2.5 15.8333 2.5H4.16663ZM3.33329 5C3.33329 4.53976 3.70639 4.16667 4.16663 4.16667H15.8333C16.2935 4.16667 16.6666 4.53976 16.6666 5V15C16.6666 15.4602 16.2935 15.8333 15.8333 15.8333H4.16663C3.70639 15.8333 3.33329 15.4602 3.33329 15V5Z'
        fill='#515151'
      />
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.5839 15.2274C2.22046 15.5909 1.63119 15.5909 1.26776 15.2274L0.772524 14.7321C0.409109 14.3687 0.409119 13.7795 0.772547 13.416L6.18862 7.99996L0.772625 2.58397C0.409196 2.22054 0.409187 1.63131 0.772602 1.26787L1.26784 0.772598C1.63127 0.40914 2.22054 0.409129 2.58398 0.772575L7.99999 6.18859L13.416 0.772575C13.7794 0.409129 14.3687 0.40914 14.7321 0.772598L15.2274 1.26787C15.5908 1.63131 15.5908 2.22054 15.2274 2.58397L9.81137 7.99996L15.2274 13.416C15.5909 13.7795 15.5909 14.3687 15.2275 14.7321L14.7322 15.2274C14.3688 15.5909 13.7795 15.5909 13.4161 15.2274L7.99999 9.81133L2.5839 15.2274Z'
        fill='#ACAEB1'
      />
    </svg>
  );
};

export const PCHeader = ({ companyHeaderData }) => {
  const { companyPageData = {} } = useCompanyDetail();

  const jobLen = companyHeaderData.job_num || 0;
  const bossLen = companyHeaderData.member_num || 0;

  const { industry, company_size } = companyPageData?.company_header || {};
  const {
    phone: phone_num,
    phone_number_list,
    email,
    more_text,
    contact_number
  } = companyPageData?.company_header || {};
  let phoneList = [phone_num, ...(phone_number_list || []).map((v) => `${v.phone_number}`)].filter(
    Boolean
  );

  const data = [
    {
      number: jobLen,
      text: companyHeaderData.job_num_desc
    },
    {
      number: bossLen,
      text: companyHeaderData.member_num_desc
    }
  ];

  const [open, setOpen] = useState(false);
  const tags = [
    {
      label: industry
    },
    {
      label: company_size
    }
  ].filter((v) => Boolean(v.label));

  const stickyRef = useRef(null);
  const [attachTop, setAttachTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (stickyRef.current) {
        const offsetTop = stickyRef.current.getBoundingClientRect().top;
        setAttachTop(offsetTop <= 0);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const cultures = (companyPageData?.company_header?.culture || []).slice(0, 4);

  return (
    <CommContentLayout>
      <div
        style={{
          borderRadius: 16
          // backgroundColor: "#f8f9fa"
        }}
      >
        <CommContent style={{ padding: 16, borderRadius: "16px", backgroundColor: "#f8f9fa" }}>
          <div>
            <div
              className={styles.headerRoot}
              style={{
                marginTop: 55,
                padding: "48px 20px 20px 20px",
                borderRadius: 16,
                position: "relative",
                backgroundColor: "#fff"
              }}
            >
              <Image
                width={90}
                height={90}
                style={{
                  position: "absolute",
                  top: -50,
                  boxShadow: "0px 5px 8px -2px rgba(162, 183, 203, 0.10)",
                  border: "3px solid #fff",
                  borderRadius: 16
                }}
                className={styles.companyLogo}
                src={companyHeaderData.logo_url}
                alt='logo'
              />

              <div className={styles.companyName}>
                {companyHeaderData.name}
                {companyHeaderData.is_verify && (
                  <Tooltip title={companyPageData?.company_header?.verify_text}>
                    <span className={""} style={{ display: "flex", alignItems: "center" }}>
                      <VerificationIcon />
                    </span>
                  </Tooltip>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 6
                }}
              >
                <div className={styles.companySizeWrapper}>
                  {tags.map((v, i) => {
                    const { label } = v;
                    return (
                      <React.Fragment key={i}>
                        {Boolean(i) && <span className={styles.dot} />}
                        <div>{label}</div>
                      </React.Fragment>
                    );
                  })}
                </div>
                {/*  */}
                <div className={styles.jobAndBoss}>
                  {data.map((v, i, arr) => {
                    return (
                      <React.Fragment key={i}>
                        {Boolean(i) && <span className={styles.splitter}></span>}
                        <span>
                          {v.number}
                          <span className={styles.unit}>{v.text}</span>
                        </span>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>

              {Boolean((email && phoneList[0]) || cultures?.length) && (
                <div className={styles.horLine}></div>
              )}

              {Boolean(email || phoneList[0]) && (
                <div className={styles.emailAndPhone}>
                  {Boolean(phoneList[0]) && (
                    <div className={styles.emailAndPhoneItem}>
                      <PhoneIcon />
                      {phoneList[0] || ""}
                      {Boolean(phoneList?.length > 1) && (
                        <span
                          className={styles.moreBtn}
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          {more_text}
                        </span>
                      )}
                    </div>
                  )}
                  {Boolean(email) && (
                    <div className={styles.emailAndPhoneItem}>
                      <EmailIcon /> {email || ""}
                    </div>
                  )}
                </div>
              )}
              {Boolean(cultures?.length) && (
                <div style={{ marginTop: 12, display: "flex", gap: 12 }}>
                  {cultures.map((v, i) => {
                    return <CultureAndLifeItem key={i}>{v.value}</CultureAndLifeItem>;
                  })}
                </div>
              )}
            </div>
          </div>
        </CommContent>
      </div>
      <CommModal open={open} setOpen={setOpen} width={420}>
        <div className={styles.phoneModalContent}>
          <div className={styles.header}>
            {contact_number}
            <span
              className={styles.closeIcon}
              onClick={(e) => {
                setOpen(false);
              }}
            >
              <CloseIcon />
            </span>
          </div>
          <div style={{ maxHeight: 156, overflow: "auto", width: "100%" }}>
            {phoneList.map((v, i) => {
              return (
                <div className={styles.listItem} key={i}>
                  <PhoneIcon />
                  {v}
                </div>
              );
            })}
          </div>
        </div>
      </CommModal>
    </CommContentLayout>
  );
};
