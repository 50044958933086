import React from "react";

import styles from "./index.module.scss";

export const MainHeader = (props: React.HTMLProps<HTMLDivElement>) => {
  const { className, ...rest } = props;

  return <div className={styles.mainHeader} {...rest}></div>;
};

export const SecondaryHeader = (props: React.HTMLProps<HTMLDivElement>) => {
  const { className, ...rest } = props;

  return <div className={styles.secondaryHeader} {...rest}></div>;
};
