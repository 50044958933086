"use client";
import Lightbox from "react-image-lightbox";

import { Card } from "../card";
import styles from "./index.module.scss";
import { useContext, useEffect, useRef, useState } from "react";
import Image from "next/image";
import classNames from "classnames";
import CompanyBrandList from "../../../components/CompanyBrandList";
import MapForClient from "app/(job)/[lang]/components/Main/Map/Map";
import { languageContext } from "app/components/providers/languageProvider";
import { HiBoss } from "../HiBoss";
import { useCompanyDetail } from "../../../DataProvider";
import { MainHeader, SecondaryHeader } from "../comm/MainHeader";
import { OverviewTable } from "./Overviewtable";

const TextEllipse = ({ text, moreText = "see more", lessText = "see less" }) => {
  const [showSeeMore, setShowSeeMore] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [isExpand, setIsExpand] = useState(false);

  useEffect(() => {
    let hasOverflowed = ref.current.clientHeight < ref.current.scrollHeight;
    setShowSeeMore(hasOverflowed);
  }, []);

  return (
    <div className={classNames(styles.companyDescription, isExpand && styles.expand)} ref={ref}>
      <span dangerouslySetInnerHTML={{ __html: text }}></span>
      {showSeeMore && (
        <div
          className={classNames(styles.expandBtn)}
          onClick={() => {
            setIsExpand((pre) => !pre);
          }}
        >
          {isExpand ? (
            <span className={styles.text}>{lessText}</span>
          ) : (
            <span>
              <span className={styles.text}>{moreText}</span>
            </span>
          )}
        </div>
      )}
    </div>
  );
};

const Section = ({ children, gap = "20px" }) => {
  return <div style={{ display: "flex", flexDirection: "column", gap }}>{children}</div>;
};

export const AboutTabPanel = () => {
  const { companyPageData } = useCompanyDetail();
  const about = companyPageData?.company_about || {};
  const {
    company_brand_product = {},
    company_album = {},
    company_addr = {},
    company_members = {}
  } = about;
  const [isOpenLightbox, setLightbox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const contextLang = useContext(languageContext);
  const pictures = company_album?.albums || [];
  const { addr, longitude, latitude } = company_addr;
  const aboutCompany = about.about_the_company || {};
  const overviewCompany = about.company_overview || {};

  return (
    <>
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 32,
          padding: "28px 20px",
          borderRadius: 12
        }}
      >
        <Section>
          <MainHeader>{aboutCompany.title || "About the Company"}</MainHeader>
          {Boolean(aboutCompany?.description) ? (
            <TextEllipse text={aboutCompany?.description} />
          ) : (
            ""
          )}
        </Section>

        <Section>
          <SecondaryHeader>{overviewCompany.title || "Company overview"}</SecondaryHeader>
          <OverviewTable />
          {/*  */}
        </Section>

        {Boolean(company_album?.title) && (
          <Section>
            <SecondaryHeader>{company_album?.title}</SecondaryHeader>
            <div className={styles.companyAlbum}>
              {(pictures || []).map((item, index) => {
                return (
                  <div className={styles.companyAlbumItem} key={index}>
                    <Image
                      src={item.url}
                      alt='alt'
                      className={styles.albumImage}
                      onClick={() => {
                        setPhotoIndex(index);
                        setLightbox(true);
                      }}
                      width={220}
                      height={134}
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                );
              })}
            </div>
            {isOpenLightbox && (
              <Lightbox
                mainSrc={pictures[photoIndex].url}
                nextSrc={pictures[(photoIndex + 1) % pictures.length].url}
                prevSrc={pictures[(photoIndex + pictures.length - 1) % pictures.length].url}
                onCloseRequest={() => setLightbox(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex(
                    (photoIndex) => (photoIndex + pictures.length - 1) % pictures.length
                  )
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex) => (photoIndex + 1) % pictures.length)
                }
              />
            )}
          </Section>
        )}

        {company_addr && (
          <Section gap='16px'>
            <SecondaryHeader> {company_addr.title} </SecondaryHeader>
            <div className={styles.mapWrapper}>
              <MapForClient
                descStyle={{
                  color: "#3B3C3C",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "24px",
                  marginBottom: 12
                }}
                data={company_addr}
                lang={contextLang.jobDetail}
              />
            </div>
          </Section>
        )}

        <HiBoss company_members={company_members} />

        {/* Brand or Product */}
        <Section>
          <SecondaryHeader>{company_brand_product.title}</SecondaryHeader>
          <CompanyBrandList />
        </Section>
      </Card>

      {/* <News /> */}
    </>
  );
};
