import React from "react";
import classNames from "classnames";

import styles from "./index.module.scss";

export const BenefitItem = (props: React.HTMLProps<HTMLSpanElement>) => {
  const { className, ...rest } = props;

  return <span className={classNames(styles.benefitItem, className)} {...rest}></span>;
};
