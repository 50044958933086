"use client";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "next/navigation";
import { useCompanyDetail } from "./DataProvider";
import { fetchViewCompany } from "store/services/companies2/fetchViewCompany";
import { getCookie, removeCookie } from "helpers/cookies";
import { COMPANY_VIEW_BURIED_COOKIE } from "components/ReportTracker/util";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import { PCHeader } from "./_pc/components/Header";
import { HotJobs } from "./_pc/components/HotJob";
import { AboutTabPanel } from "./_pc/components/AboutTabPanel";
import { CultureAndLife } from "./_pc/components/CultureAndLife";
import { JobTab } from "./_pc/components/JobTab";
import { Tab } from "./_pc/components/Tab";
import { CommContent, CommContentLayout } from "./_pc/components/Layout/index.pc";
import { SignInChatWithBoss } from "./_pc/components/SignInChatWithBoss";
import { Reminder } from "./_pc/components/Reminder";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CompanyHeader = ({ companyHeaderData }) => {
  return <PCHeader companyHeaderData={companyHeaderData} />;
};

function isElementInViewport(el) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  );
}

function TabPanel(props: TabPanelProps & { style?: any }) {
  const { children, value, index, ...other } = props;
  if (value !== index) {
    return null;
  }

  return (
    <div id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {children}
    </div>
  );
}

const Page = ({}) => {
  const searchParams = useSearchParams();
  const tabName = searchParams.get("tab");

  const { lang, dictionary, companyPageData, hasLogin, companyId } = useCompanyDetail();

  const { overview } = dictionary?.companyDetail || {};
  const params = getCookie(COMPANY_VIEW_BURIED_COOKIE);

  useEffect(() => {
    if (params) {
      fetchViewCompany({ ...params }).finally(() => removeCookie(COMPANY_VIEW_BURIED_COOKIE));
    }
  }, []);

  const headerData = companyPageData?.company_header || {};

  const hotJobData = companyPageData?.company_about?.company_hot_job || [];

  const [tabValue, setTabValue] = useState(tabName === "jobs" ? 3 : 1);
  const jobLen = headerData.job_num || 0;
  // const bossLen = headerData.member_num || 0;
  const { company_benefits, company_culture } = companyPageData?.company_culture_and_life || {};
  const { culture = [] } = company_culture || {};
  const { benefits_info = [] } = company_benefits || {};

  let tabData = [
    {
      id: 1,
      label: companyPageData.about_title // "About"
    },
    Boolean(culture.length || benefits_info.length)
      ? {
          id: 2,
          label: companyPageData.culture_and_lift_title
        }
      : null,
    jobLen
      ? {
          id: 3,
          label: (
            <>
              {headerData.job_num_desc}{" "}
              <span
                style={{
                  color: "#646566",
                  fontSize: 14,
                  fontWeight: 500,
                  lineHeight: "20px",
                  marginLeft: 4
                }}
              >
                ({jobLen})
              </span>
            </>
          )
        }
      : null
  ].filter(Boolean);

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <CompanyHeader companyHeaderData={headerData} />

      <CommContentLayout>
        <CommContent>
          <div id='viewHolder' />
          <Tab
            value={tabValue}
            setTab={(value) => {
              setTabValue(value);
              let holder = document.getElementById("viewHolder");
              if (holder && !isElementInViewport(holder)) {
                holder.scrollIntoView({ behavior: "smooth" });
              }
            }}
            data={tabData}
          />
          <div
            style={{
              marginTop: 24,
              display: "flex",
              gap: 16,
              padding: 16,
              borderRadius: "18px",
              background: "#F8F9FA"
            }}
          >
            <div
              style={{
                backgroundColor: "#f8f9fa",
                overflow: "hidden",
                borderRadius: 16,
                flex: 1
              }}
            >
              <TabPanel value={tabValue} index={1}>
                <AboutTabPanel />
              </TabPanel>
              {Boolean(culture.length || benefits_info.length) && (
                <TabPanel value={tabValue} index={2} style={{ height: "100%" }}>
                  <CultureAndLife />
                </TabPanel>
              )}
              <TabPanel value={tabValue} index={3}>
                <JobTab />
              </TabPanel>

              {/* <div style={{ margin: 10 }}>Company Update Date: July 12, 2024</div> */}
            </div>
            <div style={{ width: 348, flexShrink: 0 }}>
              {(Boolean(tabValue !== 3 && hotJobData?.hot_jobs?.length) || !hasLogin) && (
                <div
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "16px",
                    padding: 12,
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    marginBottom: 12
                  }}
                >
                  {Boolean(tabValue !== 3 && hotJobData?.hot_jobs?.length) && (
                    <div>
                      <div
                        style={{
                          color: "#000",
                          fontSize: 16,
                          fontWeight: 700,
                          lineHeight: "28px",
                          padding: "8px 12px",
                          display: "flex",
                          gap: "10px",
                          marginBottom: 8
                        }}
                      >
                        🔥 <span>{hotJobData?.title}</span>
                      </div>
                      <HotJobs
                        hotJobData={hotJobData}
                        lang={lang}
                        overview={overview}
                        headerData={headerData}
                        setTabValue={setTabValue}
                      />
                    </div>
                  )}
                  {!hasLogin && <SignInChatWithBoss />}
                </div>
              )}
              <Reminder companyId={companyId} />
            </div>
          </div>
        </CommContent>
      </CommContentLayout>
      <div style={{ height: 24 }}></div>
    </div>
  );
};

export default Page;
