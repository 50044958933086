import { Drawer, DrawerProps } from "antd";
import classNames from "classnames";

import styles from "./index.module.scss";

export const CommDrawer = (props: DrawerProps) => {
  const { placement, className, ...rest } = props;
  return (
    <Drawer
      placement='bottom'
      {...rest}
      className={classNames(styles.drawerWrapper, className)}
      title={null}
    ></Drawer>
  );
};
