/*
  Type: React Component
  Usage: control pagination, current behavior with route modification. E.g. users/1, users/2
*/
import React, { useMemo } from 'react'

import classNames from 'classnames'

import styles from './Pagination.module.scss'

const getPagination = ({ size, current, totalPage }) => {
  let start = totalPage <= size ? 1 : Math.max(current - Math.floor(size / 2), 1)
  let end = Math.min(start + size - 1, totalPage)

  if (start !== 1 && end - start + 1 < size) {
    start = end - size + 1
  }
  return Array.from({ length: end - start + 1 }, (_, i) => start + i)
}

const Right = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.86128 2.19526C5.12163 1.93491 5.54374 1.93491 5.80409 2.19526L11.1374 7.5286C11.3978 7.78895 11.3978 8.21106 11.1374 8.47141L5.80409 13.8047C5.54374 14.0651 5.12163 14.0651 4.86128 13.8047C4.60093 13.5444 4.60093 13.1223 4.86128 12.8619L9.72321 8L4.86128 3.13807C4.60093 2.87772 4.60093 2.45561 4.86128 2.19526Z"
        fill="#121212"
      />
    </svg>
  )
}

export const Pagination = ({
  current,
  pageSize,
  total = 0,
  size = 10,
  onChange
}) => {
  const totalPage = Math.ceil(total / pageSize)
  const items = useMemo(() => {
    if (!pageSize || !total || !current) {
      return []
    }
    let paginationItems = getPagination({ current, size, totalPage: Math.ceil(total / pageSize) })
    return paginationItems.map(v => {
      return {
        value: v,
        label: v
      }
    })
  }, [current, pageSize, total, size])
  if (!items.length) {
    return null
  }

  return (
    <div className={styles.pagination}>
      <span
        className={classNames(styles.actionBtn, styles.paginationItemBase, styles.prePageBtn)}
        data-disabled={current <= 1}
        onClick={() => {
          if (current <= 1) {
            return
          }
          onChange(current - 1)
        }}
      >
        <Right />
      </span>
      {items.map(v => {
        return (
          <span
            key={v.value}
            className={classNames(styles.paginationItemBase, styles.paginationItemNumber)}
            data-checked={current === v.value}
            onClick={() => {
              onChange(v.value)
            }}
          >
            {v.label}
          </span>
        )
      })}
      <span
        className={classNames(styles.actionBtn, styles.paginationItemBase)}
        data-disabled={current >= totalPage}
        onClick={() => {
          if (current >= totalPage) {
            return
          }
          onChange(current + 1)
        }}
      >
        <Right />
      </span>
    </div>
  )
}
