import { HTMLProps } from "react";

import styles from "./index.module.scss";
import classNames from "classnames";

export const Card = (props: HTMLProps<HTMLDivElement>) => {
  const { className, children, ...rest } = props;

  return (
    <div className={classNames(styles.companyDetailCard, className)} {...rest}>
      {children}
    </div>
  );
};

export const CardTitle = (props: HTMLProps<HTMLDivElement>) => {
  const { className, children, ...rest } = props;

  return (
    <div className={classNames(styles.cardTitle, className)} {...rest}>
      {children}
    </div>
  );
};

export const CommWrapper = (props: HTMLProps<HTMLDivElement>) => {
  const { className, children, ...rest } = props;

  return (
    <div className={classNames(styles.wrapper, className)} {...rest}>
      {children}
    </div>
  );
};
