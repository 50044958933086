export const StarIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <rect width='20' height='20' fill='white' />
      <path
        d='M13.3332 2.5L13.9428 6.25199C14.1526 7.54346 14.9569 8.66136 16.1146 9.27082L17.4998 10L16.1146 10.7292C14.9568 11.3386 14.1526 12.4565 13.9428 13.748L13.3332 17.5L12.7236 13.748C12.5138 12.4565 11.7095 11.3386 10.5517 10.7292L9.1665 10L10.5517 9.27082C11.7095 8.66136 12.5138 7.54346 12.7236 6.252L13.3332 2.5Z'
        stroke='#121212'
        strokeWidth='1.25'
        strokeLinejoin='round'
      />
      <path
        d='M2.5 7.50004H5.83333M4.16667 5.83337L4.16667 9.16671'
        stroke='#121212'
        strokeWidth='1.25'
        strokeLinecap='round'
      />
      <path
        d='M5.8335 15.8333H9.16683M7.50016 14.1666L7.50016 17.5'
        stroke='#121212'
        strokeWidth='1.25'
        strokeLinecap='round'
      />
    </svg>
  );
};
