import MultiSelectPc from "app/(new-pages)/components/allSelect/baseSelect/pc/MultiSelect";
import { useMemo, useState } from "react";

import styles from "./select.module.scss";
import { Input } from "antd";

export const MySelect = ({ data, value, onChange, label }) => {
  const options = useMemo(() => {
    return data?.map((v) => {
      return {
        label: v.value,
        value: v.id
      };
    });
  }, []);

  const [oneLabel, setOneLabel] = useState("");

  return (
    <MultiSelectPc
      options={options}
      value={value}
      wrapperClassName={styles.selector}
      onChange={(value) => {
        if (value?.length === 1) {
          let option = options.find((v) => v.value === value[0][0]);
          setOneLabel(option?.label || "");
        }
        onChange(value);
      }}
      visibleSearch={false}
      label={label}
      placeholder={label}
      oneLabel={oneLabel}
    />
  );
};

const SearchIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.753 19.2769L9.30859 14.2769L10.5543 13.1696L14.9987 18.1696L13.753 19.2769Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.59787 4.55656C3.98986 4.55656 1.87565 6.67077 1.87565 9.27878C1.87565 11.8868 3.98986 14.001 6.59787 14.001C9.20588 14.001 11.3201 11.8868 11.3201 9.27878C11.3201 6.67077 9.20588 4.55656 6.59787 4.55656ZM0.208984 9.27878C0.208984 5.7503 3.06939 2.88989 6.59787 2.88989C10.1264 2.88989 12.9868 5.7503 12.9868 9.27878C12.9868 12.8073 10.1264 15.6677 6.59787 15.6677C3.06939 15.6677 0.208984 12.8073 0.208984 9.27878Z'
        fill='black'
      />
      <path
        d='M13.042 4.58684C15.2194 4.58684 16.5259 2.99593 16.5259 0.723206C16.5259 2.99593 18.0501 4.58684 19.792 4.58684C18.0501 4.58684 16.5259 5.95048 16.5259 8.22321C16.5259 5.95048 15.2194 4.58684 13.042 4.58684Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.3187 3.25984C15.8446 2.66097 16.1509 1.78988 16.1509 0.723206H16.9009C16.9009 2.83606 18.3014 4.21184 19.792 4.21184V4.96184C18.2721 4.96184 16.9009 6.14228 16.9009 8.22321H16.1509C16.1509 7.15761 15.8456 6.35067 15.3297 5.81218C14.8157 5.27573 14.0508 4.96184 13.042 4.96184V4.21184C14.0331 4.21184 14.7976 3.85318 15.3187 3.25984ZM14.8311 4.5647C15.2269 4.74208 15.5764 4.98555 15.8712 5.29332C16.1523 5.5867 16.3766 5.93103 16.5432 6.31904C16.9102 5.51726 17.5094 4.91644 18.2324 4.56563C17.5061 4.17406 16.9093 3.51262 16.5438 2.67344C16.379 3.07682 16.158 3.44074 15.8822 3.75475C15.5867 4.09125 15.2338 4.3641 14.8311 4.5647Z'
        fill='black'
      />
    </svg>
  );
};

export const MyInput = ({ placeholder = "", value, onChange }) => {
  return (
    <Input
      prefix={<SearchIcon />}
      value={value}
      onChange={(e) => {
        onChange(e.target.value || "");
      }}
      placeholder={placeholder}
      className={styles.inputWrapper}
    />
  );
};
