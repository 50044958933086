import classNames from "classnames";
import React from "react";

import styles from "./index.module.scss";

export const CommContent = (props: React.HTMLProps<HTMLDivElement>) => {
  const { className, ...rest } = props;
  return <div className={classNames(className, styles.commContent)} {...rest} />;
};

export const CommContentLayout = (props: React.HTMLProps<HTMLDivElement>) => {
  const { className, ...rest } = props;

  return <div className={classNames(className, styles.commLayout)} {...rest} />;
};

export const CommHeaderLayout = (props: React.HTMLProps<HTMLDivElement>) => {
  const { className, ...rest } = props;

  return <div className={classNames(className, styles.headerLayout)} {...rest} />;
};

export const CommFooterLayout = (props: React.HTMLProps<HTMLDivElement>) => {
  const { className, ...rest } = props;

  return <div className={classNames(className, styles.footerLayout)} {...rest} />;
};
