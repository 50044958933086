import { useCompanyDetail } from "../../../DataProvider";
import { MobileCard } from "../Card";
import { BenefitItem } from "../comm/BenefitItem";
import { Section, SectionHeader } from "../SectionHeader";
import { StarIcon } from "./Icon";

import styles from "./index.module.scss";

export const CultureAndLife = () => {
  const { companyPageData } = useCompanyDetail();
  const { company_benefits, company_culture } = companyPageData?.company_culture_and_life || {};

  const { culture = [] } = company_culture || {};
  const { benefits_info = [] } = company_benefits || {};

  if (!culture?.length && !benefits_info?.length) {
    return null;
  }

  return (
    <div style={{ background: "#FDFDFD", padding: "0px 12px 12px 12px" }}>
      <MobileCard style={{ padding: "28px 12px" }}>
        {Boolean(culture?.length) && (
          <Section style={{ backgroundColor: "#fff" }}>
            {/*  */}
            <SectionHeader>{company_culture?.title}</SectionHeader>
            <div className={styles.cultureRoot}>
              {culture.map((v, i) => {
                return <BenefitItem key={i}>{v.value}</BenefitItem>;
              })}
            </div>
          </Section>
        )}

        {Boolean(benefits_info?.length) && (
          <Section
            style={{
              borderRadius: "0 0 8px 8px",
              backgroundColor: "#fff",
              marginTop: 6,
              marginBottom: 6
            }}
          >
            <SectionHeader>{company_benefits?.title}</SectionHeader>
            <div className={styles.benefitsWrapper}>
              {benefits_info.map((item, i) => {
                return (
                  <div className={styles.benefitItem} key={i}>
                    <div>
                      <StarIcon />
                    </div>
                    <div>
                      <div className={styles.benefitItemTitle}>{item.benefit_category}</div>
                      <div className={styles.benefitItemDesc}>{item.benefits_tags.join(", ")}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Section>
        )}
      </MobileCard>
    </div>
  );
};
