"use client";
import React, { useState } from "react";
import style from "./index.module.scss";

import { H5Header } from "./_h5/components/Header";
import { AboutPage } from "./_h5/components/AboutPage";
import { CultureAndLife } from "./_h5/components/CultureAndLife";
import { JobPage } from "./_h5/components/JobPage";
import { Tab } from "./_h5/components/comm/Tab";
import { useCompanyDetail } from "./DataProvider";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  if (value !== index) {
    return null;
  }

  return (
    <div id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

const MobilePage = () => {
  const [tabValue, setTabValue] = useState(1);
  const { dictionary, companyPageData } = useCompanyDetail();
  const companyHeaderData = companyPageData?.company_header || {};

  const jobLen = companyHeaderData.job_num || 0;
  // const bossLen = companyHeaderData.member_num || 0;
  const { company_benefits, company_culture } = companyPageData?.company_culture_and_life || {};
  const { culture = [] } = company_culture || {};
  const { benefits_info = [] } = company_benefits || {};

  let tabData = [
    {
      id: 1,
      label: companyPageData.about_title
    },
    Boolean(culture.length || benefits_info.length)
      ? {
          id: 2,
          label: companyPageData.culture_and_lift_title
        }
      : null,
    jobLen
      ? {
          id: 3,
          label: (
            <div>
              {companyHeaderData.job_num_desc}{" "}
              <span
                style={{
                  color: "#646566",
                  fontSize: 14,
                  fontWeight: 500,
                  lineHeight: "20px"
                  // marginLeft: 4,
                }}
              >
                ({jobLen})
              </span>
            </div>
          )
        }
      : null
  ].filter(Boolean);

  return (
    <div className={style.container} style={{ backgroundColor: "#FDFDFD" }}>
      <H5Header tab={tabValue} setTab={setTabValue} />

      <div
        style={{
          padding: "8px 20px",
          backgroundColor: "#FDFDFD",
          marginTop: 12
        }}
      >
        <Tab value={tabValue} setTab={setTabValue} data={tabData} />
      </div>

      <TabPanel value={tabValue} index={1}>
        <AboutPage />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <CultureAndLife />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <JobPage />
      </TabPanel>
    </div>
  );
};

export default MobilePage;
