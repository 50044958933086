import Modal from "antd/es/modal/Modal";

import styles from "./index.module.scss";
import classNames from "classnames";

export const CommModal = ({ open, setOpen, children, width = 520 }) => {
  return (
    <Modal
      width={width}
      open={open}
      className={classNames(styles.commCompanyDetailModal, "ta-ztl")}
      footer={null}
      onCancel={() => {
        setOpen(false);
      }}
    >
      {children}
    </Modal>
  );
};
