"use client";
import React, { useState, useLayoutEffect, useRef, useContext } from "react";
import style from "./index.module.scss";

import Image from "next/image";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import Lightbox from "react-image-lightbox";

export function MobileAlbum({ pictures }) {
  const [isOpenLightbox, setLightbox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const res = pictures;
  if (!res?.length) return null;

  return (
    <div>
      <Swiper spaceBetween={8} slidesPerView={"auto"} scrollbar={{ draggable: true }}>
        {res.map((item, index) => {
          return (
            <SwiperSlide key={index} style={{ width: "160px" }}>
              <div className={style.mobile_album}>
                <Image
                  style={{ objectFit: "cover" }}
                  fill
                  src={item.url}
                  alt='album'
                  onClick={() => {
                    setPhotoIndex(index);
                    setLightbox(true);
                  }}
                ></Image>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {isOpenLightbox && (
        <Lightbox
          mainSrc={res[photoIndex].url}
          nextSrc={res[(photoIndex + 1) % res.length].url}
          prevSrc={res[(photoIndex + res.length - 1) % res.length].url}
          onCloseRequest={() => setLightbox(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex) => (photoIndex + res.length - 1) % res.length)
          }
          onMoveNextRequest={() => setPhotoIndex((photoIndex) => (photoIndex + 1) % res.length)}
        />
      )}
    </div>
  );
}
