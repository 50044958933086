import React from "react";

import styles from "./index.module.scss";

export const Tab = ({ value, setTab, data }) => {
  return (
    <div className={styles.tabRoot}>
      {data.map((v) => {
        return (
          <div
            className={styles.tabItem}
            data-checked={value === v.id}
            key={v.id}
            onClick={() => {
              setTab(v.id);
            }}
          >
            {v.label}
          </div>
        );
      })}
    </div>
  );
};
