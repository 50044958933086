import { HTMLProps } from "react";

import classNames from "classnames";

import styles from "./index.module.scss";

export const SectionHeader = (props: HTMLProps<HTMLDivElement>) => {
  const { className, ...rest } = props;
  return <div className={classNames(styles.sectionHeader, className)} {...rest}></div>;
};

export const SecondarySectionHeader = (props: HTMLProps<HTMLDivElement>) => {
  const { className, ...rest } = props;
  return <div className={classNames(styles.sectionSecondaryHeader, className)} {...rest}></div>;
};

export const Section = (props: HTMLProps<HTMLDivElement>) => {
  const { className, ...rest } = props;
  return <div className={classNames(styles.sectionRoot, className)} {...rest}></div>;
};
