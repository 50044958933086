import { Section, SectionHeader } from "../SectionHeader";
import { Swiper, SwiperSlide } from "swiper/react";

import styles from "./index.module.scss";
import { useContext, useState } from "react";
import { useCompanyDetail } from "../../../DataProvider";

import { Preview } from "../Preview";
import { languageContext } from "app/components/providers/languageProvider";
import "swiper/css";

const OnlineIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='11' height='10' viewBox='0 0 11 10' fill='none'>
      <circle cx='5.88281' cy='5' r='4.5' fill='#34C759' stroke='white' />
    </svg>
  );
};

export const HiBoss = () => {
  const { companyPageData } = useCompanyDetail();
  const data = companyPageData?.company_about?.company_members || {};
  const [recruiterId, setRecruiterId] = useState(null);
  const contextLang = useContext(languageContext);
  const { overview } = contextLang.companyDetail;

  return (
    <Section style={{ marginBottom: 0 }}>
      <SectionHeader>{data.title}</SectionHeader>
      <Swiper style={{ width: "100%" }} spaceBetween={8} slidesPerView={"auto"}>
        {(data?.members || []).map((item, i) => {
          const inOnLine = item?.is_online;

          return (
            <SwiperSlide
              className={styles.swiperSlideItem}
              onClick={() => {
                // setItem(item);
                setRecruiterId(item.id);
              }}
            >
              <span
                style={{ position: "relative", display: "inline-block", height: 56, width: 56 }}
              >
                <img src={item.avatar} />
                {inOnLine && (
                  <span style={{ position: "absolute", right: 0, bottom: 4 }}>
                    <OnlineIcon />
                  </span>
                )}
              </span>
              <div className={styles.content}>
                <div className={styles.name}>{item.full_name}</div>
                <div className={styles.position}>{item.job_title}</div>
                <div className={styles.replayLabel}>{item.reply_label}</div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* {recruiterId && (
        <Preview Id={recruiterId} setRecruiterId={setRecruiterId} overview={overview}></Preview>
      )} */}
    </Section>
  );
};
