import FeedBack from "components/Footer/components/PC/FeedBack";
import styles from "./index.module.scss";
import { useState } from "react";
import { useCompanyDetail } from "../../../DataProvider";
import { getLanguageCode } from "helpers/country";
import { useSharedData } from "bossjob-remote/dist/hooks";
import { getCookie } from "helpers/cookies";
import { postNotification } from "bossjob-remote/dist/clientStorage";
import { Modal, message } from "antd";
import classNames from "classnames";
import { fetchReportService } from "../../../service";

const ReminderIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M11.4885 23.8455C11.642 23.945 11.8188 23.9984 11.9993 23.9997C12.1799 23.9984 12.3567 23.945 12.5102 23.8455C12.6772 23.7427 16.4789 21.2759 17.805 20.2481C19.08 19.2664 20.1247 17.994 20.8601 16.5273C21.1412 15.9307 21.3017 15.2799 21.3317 14.6155V5.1902C21.3416 4.96348 21.2795 4.7397 21.1551 4.55377C21.0307 4.36783 20.851 4.23021 20.644 4.16236L12.2941 1.38719C12.1037 1.31495 11.895 1.31495 11.7046 1.38719L3.35469 4.16236C3.14769 4.23021 2.96798 4.36783 2.84359 4.55377C2.7192 4.7397 2.65712 4.96348 2.66704 5.1902V14.6463C2.70128 15.3002 2.86168 15.94 3.13857 16.5273C3.87595 17.9926 4.92034 19.2645 6.19367 20.2481C7.55914 21.317 11.3215 23.7427 11.4885 23.8455Z'
        fill='#1C70EE'
      />
      <path
        d='M10.8977 15.9999C10.9019 15.9999 10.9061 16 10.9103 16H10.8899L10.8977 15.9999Z'
        fill='white'
      />
      <path
        d='M10.5117 15.9179C10.6337 15.9699 10.7649 15.9977 10.8977 15.9999C11.0353 15.9971 11.171 15.9668 11.2965 15.9106C11.4244 15.8534 11.5389 15.7706 11.6329 15.6675L17.3327 9.34005L15.806 8L10.8492 13.5013L8.10113 10.6297L6.66602 12.0101L10.1775 15.6877C10.2721 15.7859 10.3857 15.8642 10.5117 15.9179Z'
        fill='white'
      />
    </svg>
  );
};
const DotIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4' fill='none'>
      <circle cx='2' cy='2' r='1.25' fill='#ACAEB1' />
    </svg>
  );
};
interface ReportCompanyProps {
  companyId: string;
  sessionid: string;
}

interface ReportOption {
  title: string;
  subOptions: {
    title: string;
    description: string;
  }[];
}

//companyDetail.reminder.subOption1.title2
//companyDetail.reminder.subOption1.desc1
export const Reminder = (props) => {
  const { companyId } = props;
  const langDic = useSharedData("DICTIONARY") || {};
  const new_report_company_reasons = useSharedData("CONFIG")?.new_report_company_reasons || [];
  const reportOptions: ReportOption[] = [
    {
      title: langDic?.companyDetail?.reminder?.title1,
      subOptions: new_report_company_reasons.filter((item) => item?.category === "spam")
      // subOptions: [
      //   {
      //     title: langDic?.companyDetail?.reminder?.subOption1?.title1,
      //     description: langDic?.companyDetail?.reminder?.subOption1?.desc1
      //   },
      //   {
      //     title: langDic?.companyDetail?.reminder?.subOption1?.title2,
      //     description: langDic?.companyDetail?.reminder?.subOption1?.desc2
      //   }
      // ]
    },
    {
      title: langDic?.companyDetail?.reminder?.title2,
      subOptions: new_report_company_reasons.filter((item) => item?.category === "discriminatory")
      // subOptions: [
      //   {
      //     title: langDic?.companyDetail?.reminder?.subOption2?.title1,
      //     description: langDic?.companyDetail?.reminder?.subOption2?.desc1
      //   },
      //   {
      //     title: langDic?.companyDetail?.reminder?.subOption2?.title2,
      //     description: langDic?.companyDetail?.reminder?.subOption2?.desc2
      //   },
      //   //companyDetail.reminder.subOption2.title3
      //   //companyDetail.reminder.subOption2.desc3
      //   {
      //     title: langDic?.companyDetail?.reminder?.subOption2?.title3,
      //     description: langDic?.companyDetail?.reminder?.subOption2?.desc3
      //   }
      // ]
    },
    {
      title: langDic?.companyDetail?.reminder?.title3,
      subOptions: new_report_company_reasons.filter((item) => item?.category === "broken")
      // subOptions: [
      //   {
      //     title: langDic?.companyDetail?.reminder?.subOption3?.title1,
      //     description: langDic?.companyDetail?.reminder?.subOption3?.desc1
      //   },
      //   {
      //     title: langDic?.companyDetail?.reminder?.subOption3?.title2,
      //     description: langDic?.companyDetail?.reminder?.subOption3?.desc2
      //   },
      //   {
      //     title: langDic?.companyDetail?.reminder?.subOption3?.title3,
      //     description: langDic?.companyDetail?.reminder?.subOption3?.desc3
      //   },
      //   {
      //     title: langDic?.companyDetail?.reminder?.subOption3?.title4,
      //     description: langDic?.companyDetail?.reminder?.subOption3?.desc4
      //   },

      //   {
      //     title: langDic?.companyDetail?.reminder?.subOption3?.title5,
      //     description: langDic?.companyDetail?.reminder?.subOption3?.desc5
      //   }
      // ]
    }
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMainOption, setSelectedMainOption] = useState<string>("");
  const [isSubOptionsVisible, setIsSubOptionsVisible] = useState(false);
  const [selectedSubOption, setSelectedSubOption] = useState<any>();
  const [openForm, setOpenForm] = useState({
    open: false,
    key: 0
  });
  const { companyPageData } = useCompanyDetail();
  const about = companyPageData.company_about;

  const data = [
    about.imm_text_one,
    about.imm_text_two,
    about.imm_text_three,
    about.imm_text_four,
    about.imm_text_five,
    about.imm_text_six
  ].filter(Boolean);
  const [submiting, setSubmiting] = useState(false);
  const langKey = getLanguageCode();
  let FB = FeedBack as any;
  const handleMainOptionSelect = (option: string) => {
    setSelectedMainOption(option);
    setIsSubOptionsVisible(true);
  };

  const handleBack = () => {
    setIsSubOptionsVisible(false);
    setSelectedSubOption(undefined);
  };

  const handleSubmit = async () => {
    if (!selectedSubOption) {
      message.error("Please select a specific reason for reporting");
      return;
    }
    try {
      if (submiting) {
        return;
      }
      setSubmiting(true);
      await fetchReportService(companyId, selectedSubOption?.id).finally(() => {
        setSubmiting(false);
      });
      message.success("Report submitted successfully");
      setIsModalOpen(false);
      setIsSubOptionsVisible(false);
      setSelectedMainOption("");
      setSelectedSubOption(undefined);
    } catch (error) {
      message.error("Failed to submit report. Please try again later.");
    }
  };

  const getSubOptions = () => {
    const option = reportOptions.find((opt) => opt.title === selectedMainOption);
    return option?.subOptions || [];
  };
  const onOpenClick = () => {
    if (getCookie("sessionid")) {
      setIsModalOpen(true);
    } else {
      postNotification("SHOW_LOGIN_MODAL");
    }
  };
  return (
    <>
      <div>
        <div style={{ padding: 12, borderRadius: 16, backgroundColor: "#fff" }}>
          <div className={styles.reminderRoot}>
            <div style={{ textAlign: "center" }}>
              <ReminderIcon />
            </div>
            <div className={styles.header}> {about.safety_title}</div>
            <div className={styles.bodyText1}>{about.safety_text}</div>
            <div className={styles.bodyText2}>
              <div>{about.safety_text_one}</div>
            </div>
            <div
              style={{ marginTop: 16 }}
              dangerouslySetInnerHTML={{
                __html: `${about.safety_text_two}`
              }}
            />
            <ul className={styles.list}>
              {data.map((v) => {
                return (
                  <li className={styles.listItem} key={v}>
                    <span className={styles.dotWrapper}>
                      <DotIcon />
                    </span>
                    {v}
                  </li>
                );
              })}
            </ul>

            <div
              className={styles.btnALike}
              style={{ marginTop: 16 }}
              onClick={(e) => {
                const target = e.nativeEvent.target as HTMLSpanElement;
                if (target.className === "aLikeText") {
                  onOpenClick();
                  return;
                }
              }}
              dangerouslySetInnerHTML={{
                __html: `<span class="aLikeText">${about.please_report_it_btn}</span>`
              }}
            />
          </div>
        </div>
      </div>
      <FB
        key={openForm.key}
        open={Boolean(openForm.open)}
        onCancel={() => {
          setOpenForm((pre) => {
            return { ...pre, open: false };
          });
        }}
        lang={langDic}
        langKey={langKey}
      />

      <Modal
        title={
          !isSubOptionsVisible ? (
            <div className={styles.modalTitle}>{langDic?.companyDetail?.reminder?.modalTitle}</div>
          ) : (
            <div className={styles.backIcon} onClick={handleBack}>
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M8.17847 10.0001L13.5782 4.60034C13.9037 4.27491 13.9037 3.74727 13.5782 3.42183L13.4015 3.24505C13.076 2.91962 12.5484 2.91962 12.2229 3.24505L5.4679 10.0001L12.2229 16.7552C12.5484 17.0806 13.076 17.0806 13.4015 16.7552L13.5782 16.5784C13.9037 16.2529 13.9037 15.7253 13.5782 15.3999L8.17847 10.0001Z'
                  fill='#ACAEB1'
                />
              </svg>
            </div>
          )
        }
        className={styles.modal}
        open={isModalOpen}
        footer={null}
        onCancel={() => {
          setIsModalOpen(false);
          setIsSubOptionsVisible(false);
          setSelectedMainOption("");
          setSelectedSubOption("");
        }}
        width={480}
        style={{ padding: "0px" }}
      >
        <>
          {!isSubOptionsVisible ? (
            <div className={styles.mainOptions}>
              {reportOptions.map((option, index) => (
                <div
                  key={index}
                  className={styles.mainOptionItem}
                  onClick={() => handleMainOptionSelect(option.title)}
                >
                  <div className={styles.mainOptionTitle}>{option.title}</div>
                  <div className={styles.arrowIcon}>›</div>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.subOptionsContainer}>
              <div className={styles.subOptions}>
                {getSubOptions().map((subOption, index) => (
                  <div
                    key={index}
                    className={styles.subOptionItem}
                    onClick={() => setSelectedSubOption(subOption)}
                  >
                    <div
                      className={classNames({
                        [styles.circle]: true,
                        [styles.checked]: selectedSubOption?.title === subOption.title
                      })}
                      style={{ width: 20 }}
                    >
                      <svg
                        width='12'
                        height='9'
                        viewBox='0 0 12 9'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          id='Vector 4226'
                          d='M1.42188 3.23438L4.98438 6.79688L10.5781 1.20312'
                          stroke='white'
                          stroke-width='2'
                        />
                      </svg>
                    </div>
                    <div className={styles.subOptionContent}>
                      <div className={styles.subOptionTitle}>{subOption.title}</div>
                      <div className={styles.subOptionDesc}>{subOption.description}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div style={{ flex: 1 }} />
              <div className={styles.actionButtons}>
                <button className={styles.cancelBtn} onClick={handleBack}>
                  {langDic?.companyDetail?.reminder?.modalCancelButton}
                </button>
                <button
                  className={styles.confirmBtn}
                  onClick={handleSubmit}
                  disabled={!selectedSubOption}
                >
                  {langDic?.companyDetail?.reminder?.modalConfirmButton}
                </button>
              </div>
            </div>
          )}
          {/* <div style={{ height: 300, background: "none" }} /> */}
        </>
      </Modal>
    </>
  );
};
