import { useCompanyDetail } from "../../../DataProvider";
import { Section, SectionHeader } from "../SectionHeader";

import styles from "./index.module.scss";

const LocationIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
      <path
        d='M9.00073 4.43701C7.32648 4.43701 5.96924 5.79426 5.96924 7.46851C5.96924 9.14275 7.32648 10.5 9.00073 10.5C10.675 10.5 12.0322 9.14275 12.0322 7.46851C12.0322 5.79426 10.675 4.43701 9.00073 4.43701ZM7.46924 7.46851C7.46924 6.62269 8.15491 5.93701 9.00073 5.93701C9.84655 5.93701 10.5322 6.62269 10.5322 7.46851C10.5322 8.31433 9.84655 9 9.00073 9C8.15491 9 7.46924 8.31433 7.46924 7.46851Z'
        fill='#B8B8B8'
      />
      <path
        d='M8.09458 16.198C8.63372 16.6042 9.37781 16.6003 9.9126 16.1883C10.5468 15.6998 11.9549 14.5499 13.2186 13.0432C14.4412 11.5854 15.75 9.53496 15.75 7.27297C15.75 3.61272 12.6605 0.75 9 0.75C5.33954 0.75 2.25 3.61272 2.25 7.27297C2.25 9.56924 3.54958 11.6215 4.77847 13.081C6.04366 14.5836 7.45452 15.7157 8.09458 16.198ZM14.25 7.27297C14.25 10.7025 10.1857 14.0846 8.99724 15C7.80331 14.1004 3.75 10.7817 3.75 7.27297C3.75 4.50295 6.10478 2.25 9 2.25C11.8952 2.25 14.25 4.50295 14.25 7.27297Z'
        fill='#B8B8B8'
      />
    </svg>
  );
};

const PlaneIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <g clip-path="url(#clip0_1126_71774)">
        <path d="M12.3612 3.08001C12.6652 2.16796 11.7975 1.30026 10.8855 1.60428L1.57023 4.70937C0.506585 5.06391 0.506583 6.56841 1.57023 6.92296L5.12106 8.10657C5.46943 8.22269 5.7428 8.49606 5.85892 8.84444L7.04253 12.3953C7.39708 13.4589 8.90158 13.4589 9.25613 12.3953L12.3612 3.08001Z" fill="#1C70EE"/>
      </g>
      <defs>
        <clipPath id="clip0_1126_71774">
          <rect width="14" height="14" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export const Location = () => {
  const { companyPageData } = useCompanyDetail();
  const about = companyPageData?.company_about || {};
  const { company_addr = {} } = about;
  const { latitude, longitude } = company_addr;

  return (
    <Section>
      <SectionHeader>{company_addr.title}</SectionHeader>
      <div className={styles.root}>
          <div className={styles.addressDetail}>{company_addr.addr}</div>
          <a
            href={`https://www.google.com/maps?q=${latitude},${longitude}`}
            className={styles.planeWrapper}
            target='_blank'
          >
            <PlaneIcon />
            {about.view_on_map}
            {/* Get direction */}
          </a>
        </div>
    </Section>
  );
};
