import React, { useState } from "react";
import { Image, Popover } from "antd";
import styled from "@emotion/styled";
import BrandPopup from "app/components/companyBrand/brandPopover";

import { useCompanyDetail } from "../../DataProvider";
import classNames from "classnames";
import { Button } from "app/(new-pages)/components/Button/pc/index.pc";

const maxCount = 6;
export const CompanyBrandList = () => {
  const { companyPageData } = useCompanyDetail();
  const { company_brand_product = {} } = companyPageData?.company_about || {};
  const {
    brand_and_product_info: brandProductList = [],
    more_text,
    less_text
  } = company_brand_product || {};
  const [showAll, setShowAll] = useState(false);
  const displayCount = showAll ? brandProductList.length : maxCount;

  if (!brandProductList.length) {
    return null;
  }

  return (
    <CompanyBrandWrap>
      <div className='brand-wrap'>
        {brandProductList.slice(0, displayCount).map((item: any, index) => {
          return (
            <div key={index} className='brand-card'>
              <Popover trigger='hover' content={<BrandPopup data={item} />}>
                <div className='brand-item'>
                  <span className='avatar-bg'>
                    <Image src={item.logo_url} preview={false} />
                  </span>
                  <div className='item-right'>
                    <div className='brand-name'>{item.title}</div>
                    <div className='brand-intro'>{item.description}</div>
                  </div>
                </div>
              </Popover>
            </div>
          );
        })}
      </div>

      {brandProductList.length > maxCount && (
        <div className={classNames("more", showAll && "open")} onClick={() => setShowAll(!showAll)}>
          <Button variant='grayBtn' btnSize='size44'>
            {showAll ? less_text : more_text}
          </Button>
        </div>
      )}
    </CompanyBrandWrap>
  );
};

export default CompanyBrandList;

const CompanyBrandWrap = styled.div`
  .brand-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 12px; /* 设置卡片之间的水平和垂直间距 */
    position: relative;
  }
  .brand-card {
    position: relative;
    display: inline-flex;
    gap: 16px;
    padding: 20px 16px;

    width: calc(50% - 6px); /* 设置卡片宽度为一行的一半减去间距 */
    border-radius: 12px;
    border: 3px solid var(--line-border-01-f-8-f-9-fa-primary, #f8f9fa);
    background: #fff;
    box-sizing: border-box;

    &:hover {
      border: 3px solid var(--global-alpha-brand-brand-25, rgba(0, 102, 255, 0.25));
    }

    .brand-item {
      display: flex;
      overflow: hidden;
    }
    .item-popup {
      display: none;
      position: absolute;
      right: 0;
      top: 82px;
      z-index: 10;
    }
    .avatar-bg {
      display: inline-block;
      width: 48px;
      height: 48px;
      margin-right: 16px;
      flex-shrink: 0;
      img {
        border-radius: 6px;
        width: 100%;
      }
    }
    .item-right {
      overflow: hidden;
    }

    .brand-name {
      color: var(--neutral-color-text-06121212, #121212);
      font-family: "SF Pro Display";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      /*  */
      white-space: nowrap; /* 防止换行 */
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .brandSlogan {
      margin-top: 6px;
      line-height: 1.2;
      color: #7d7d7d;
      font-size: 12px;
      white-space: nowrap; /* 防止换行 */
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .brand-intro {
      margin-top: 8px;
      line-height: 1.2;
      color: #7d7d7d;
      font-size: 12px;

      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
  }
  .btn-add {
    display: flex;
    align-items: center;
    color: #136fd3;
    cursor: pointer;
    font-size: 14px;
    cursor: pointer;
  }
  .right-icon {
    margin-left: 8px;
    display: inline-block;
    vertical-align: middle;
  }
  .btnOperation {
    /* display: none; */
    float: right;
    svg {
      margin-left: 18px;
      &:hover {
        cursor: pointer;
        path {
          fill: #2378e5;
        }
      }
    }
  }
  .more {
    margin-top: 24px;
    text-align: center;
  }
`;
