import React from "react";

import style from "./index.module.scss";
import classNames from "classnames";

export const MobileCard = (props: React.HTMLProps<HTMLDivElement>) => {
  const { className, ...rest } = props;

  return <div className={classNames(style.mobileCard, className)} {...rest} />;
};
