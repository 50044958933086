import Image from "next/image";
import { Tooltip } from "antd";
import { useCompanyDetail } from "../../../DataProvider";

import styles from "./index.module.scss";
import { MobileCard } from "../Card";
import { BenefitItem } from "../comm/BenefitItem";
import { CommDrawer } from "../comm/Drawer";
import { useState } from "react";

const VerificationIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M9.30027 0.685356C9.68905 0.304565 10.311 0.304565 10.6997 0.685356L12.6594 2.60476C12.8437 2.78531 13.0907 2.88762 13.3487 2.8903L16.0917 2.91877C16.6358 2.92442 17.0756 3.36417 17.0812 3.90834L17.1097 6.65125C17.1124 6.90927 17.2147 7.15627 17.3952 7.34061L19.3146 9.30027C19.6954 9.68905 19.6954 10.311 19.3146 10.6997L17.3952 12.6594C17.2147 12.8437 17.1124 13.0907 17.1097 13.3487L17.0812 16.0917C17.0756 16.6358 16.6358 17.0756 16.0917 17.0812L13.3487 17.1097C13.0907 17.1124 12.8437 17.2147 12.6594 17.3952L10.6997 19.3146C10.311 19.6954 9.68905 19.6954 9.30027 19.3146L7.34061 17.3952C7.15627 17.2147 6.90927 17.1124 6.65125 17.1097L3.90834 17.0812C3.36417 17.0756 2.92442 16.6358 2.91877 16.0917L2.8903 13.3487C2.88762 13.0907 2.78531 12.8437 2.60476 12.6594L0.685356 10.6997C0.304565 10.311 0.304565 9.68905 0.685356 9.30027L2.60476 7.34061C2.78531 7.15627 2.88762 6.90927 2.8903 6.65125L2.91877 3.90834C2.92442 3.36417 3.36417 2.92442 3.90834 2.91877L6.65125 2.8903C6.90927 2.88762 7.15627 2.78531 7.34061 2.60476L9.30027 0.685356Z'
        fill='#4BD9E3'
      />
      <path
        d='M6.54102 9.61217L9.08471 12.1696L13.4542 7.82938'
        stroke='black'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
const Dot = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4' fill='none'>
      <circle cx='2' cy='2' r='2' fill='black' fillOpacity='0.5' />
    </svg>
  );
};

const PhoneIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path d='M7.08268 6.66666H12.916V4.99999H7.08268V6.66666Z' fill='#515151' />
      <path
        d='M9.99935 15.4167C10.9198 15.4167 11.666 14.6705 11.666 13.75C11.666 12.8295 10.9198 12.0833 9.99935 12.0833C9.07887 12.0833 8.33268 12.8295 8.33268 13.75C8.33268 14.6705 9.07887 15.4167 9.99935 15.4167Z'
        fill='#515151'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.41602 1.66666C4.0353 1.66666 2.91602 2.78594 2.91602 4.16666L2.91602 15.8333C2.91602 17.214 4.0353 18.3333 5.41602 18.3333H14.5827C15.9634 18.3333 17.0827 17.214 17.0827 15.8333V4.16666C17.0827 2.78595 15.9634 1.66666 14.5827 1.66666L5.41602 1.66666ZM4.58268 4.16666C4.58268 3.70642 4.95578 3.33332 5.41602 3.33332L14.5827 3.33332C15.0429 3.33332 15.416 3.70642 15.416 4.16666V15.8333C15.416 16.2936 15.0429 16.6667 14.5827 16.6667H5.41602C4.95578 16.6667 4.58268 16.2936 4.58268 15.8333L4.58268 4.16666Z'
        fill='#515151'
      />
    </svg>
  );
};

const EmailIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M10.4616 11.5267L16.1549 7.73115L15.2304 6.3444L9.99932 9.83182L4.7682 6.3444L3.8437 7.73115L9.53707 11.5267C9.81699 11.7133 10.1817 11.7133 10.4616 11.5267Z'
        fill='#515151'
      />
      <path
        d='M4.16602 2.5C2.7853 2.5 1.66602 3.61929 1.66602 5V15C1.66602 16.3807 2.7853 17.5 4.16602 17.5H15.8327C17.2134 17.5 18.3327 16.3807 18.3327 15V5C18.3327 3.61929 17.2134 2.5 15.8327 2.5H4.16602ZM3.33268 5C3.33268 4.53976 3.70578 4.16667 4.16602 4.16667H15.8327C16.2929 4.16667 16.666 4.53976 16.666 5V15C16.666 15.4602 16.2929 15.8333 15.8327 15.8333H4.16602C3.70578 15.8333 3.33268 15.4602 3.33268 15V5Z'
        fill='#515151'
      />
    </svg>
  );
};

const EmailAndPhone = () => {
  const { companyPageData } = useCompanyDetail();
  let {
    email,
    phone,
    phone_number_list = [],
    more_text,
    contact_number
  } = companyPageData.company_header || {};
  const [open, setOpen] = useState(false);

  let phoneList = [phone, ...(phone_number_list || []).map((v) => `${v.phone_number}`)].filter(
    Boolean
  );

  return (
    <>
      <div className={styles.emailAndPhoneRoot}>
        <div className={styles.item}>
          <PhoneIcon /> {phoneList[0] || "--"}
          {Boolean(phoneList.length > 1) && (
            <span
              className={styles.moreBtn}
              onClick={(e) => {
                setOpen(true);
              }}
            >
              {more_text}
            </span>
          )}
        </div>
        <div className={styles.item}>
          <EmailIcon /> {email || "--"}
        </div>
      </div>
      <CommDrawer
        open={open}
        onClose={(e) => [setOpen(false)]}
        styles={{ wrapper: { height: "70vh" } }}
      >
        <div
          style={{
            height: "100%",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <div className={styles.drawerHeader}>
            <div
              className={styles.mobileCloseHolder}
              onClick={(e) => {
                setOpen(false);
              }}
            ></div>
          </div>
          <div className={styles.headerText}>{contact_number}</div>
          <div className={styles.contentRoot} style={{ flex: 1 }}>
            {phoneList.map((v, i) => {
              return (
                <div className={styles.phoneItem} key={i}>
                  {v}
                </div>
              );
            })}
          </div>
        </div>
      </CommDrawer>
    </>
  );
};

export const H5Header = ({ tab, setTab }) => {
  const { dictionary, companyPageData } = useCompanyDetail();
  const companyHeaderData = companyPageData?.company_header || {};

  const jobLen = companyHeaderData.job_num || 0;
  const bossLen = companyHeaderData.member_num || 0;

  const data = [
    {
      number: jobLen,
      text: companyHeaderData.job_num_desc
    },
    {
      number: bossLen,
      text: companyHeaderData.member_num_desc
    }
  ];

  const benefit = (companyPageData.company_header?.culture || []).slice(0, 4);

  return (
    <div className={styles.headerRoot}>
      <div
        style={{
          height: 40,
          alignSelf: "stretch",
          borderRadius: "12px",
          background: "#f8f9fa"
        }}
      />
      <div style={{ position: "absolute", left: 28, top: 22, zIndex: 1 }}>
        <Image
          width={64}
          height={64}
          className={styles.companyLogo}
          src={companyHeaderData.logo_url}
          alt='logo'
        />
      </div>
      <MobileCard
        style={{
          padding: "40px 16px 20px 16px",
          position: "relative",
          backgroundColor: "#fff",
          width: "100%"
        }}
      >
        <div className={styles.companyName}>
          <div>{companyHeaderData.name}</div>
          {companyHeaderData.is_verify && (
            <Tooltip title={companyPageData.company_header?.verify_text}>
              <span style={{ display: "flex", alignItems: "center" }}>
                <VerificationIcon />
              </span>
            </Tooltip>
          )}
        </div>
        <div className={styles.displayName}>{companyHeaderData.name}</div>

        <div className={styles.jobAndBoss}>
          {data.map((v, i) => {
            return (
              <>
                {Boolean(i) && <Dot />}
                <span key={v.text}>
                  {v.number}
                  <span className={styles.unit}>{v.text}</span>
                </span>
              </>
            );
          })}
        </div>

        <div className={styles.horLine} />

        <EmailAndPhone />

        {Boolean(benefit?.length) && (
          <div
            style={{
              display: "flex",
              height: 30,
              overflow: "hidden",
              flexDirection: "row",
              gap: 4,
              flexWrap: "wrap",
              marginTop: 12
            }}
          >
            {benefit.map((v, i) => {
              return (
                <BenefitItem style={{ height: 30 }} key={v.id}>
                  {v.value}
                </BenefitItem>
              );
            })}
          </div>
        )}

        {/*  */}
      </MobileCard>
    </div>
  );
};
