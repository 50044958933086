import { useCompanyDetail } from "../../../DataProvider";
import { CultureAndLifeItem } from "../comm/cultureAndLifeItem";
import { MainHeader } from "../comm/MainHeader";
import { StarIcon } from "./Icon";

import styles from "./index.module.scss";

const Section = ({ children, gap = "20px" }) => {
  return <div style={{ display: "flex", flexDirection: "column", gap }}>{children}</div>;
};

export const CultureAndLife = () => {
  const { companyPageData } = useCompanyDetail();
  const { company_benefits, company_culture } = companyPageData?.company_culture_and_life || {};
  const { culture = [] } = company_culture || {};
  const { benefits_info = [] } = company_benefits || {};

  if (!culture?.length && !benefits_info?.length) {
    return null;
  }

  return (
    <div
      style={{
        padding: "28px 40px 40px 40px",
        display: "flex",
        flexDirection: "column",
        gap: 44,
        borderRadius: 16,
        backgroundColor: "#FFF",
        height: "100%"
      }}
    >
      {Boolean(culture.length) && (
        <Section>
          <MainHeader>{company_culture?.title}</MainHeader>
          <div className={styles.cultureRoot}>
            {culture.map((v, i) => {
              return <CultureAndLifeItem key={i}>{v.value}</CultureAndLifeItem>;
            })}
          </div>
        </Section>
      )}

      {Boolean(benefits_info?.length) && (
        <Section>
          <MainHeader>{company_benefits?.title}</MainHeader>
          <div className={styles.benefitsWrapper}>
            {benefits_info.map((item, i) => {
              return (
                <div className={styles.benefitItem} key={i}>
                  <div>
                    <StarIcon />
                  </div>
                  <div>
                    <div className={styles.benefitItemTitle}>{item.benefit_category}</div>
                    <div className={styles.benefitItemDesc}>{item.benefits_tags.join(", ")}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </Section>
      )}
    </div>
  );
};
